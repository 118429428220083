/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


/* GTA-Blau: #0095ff */

body {
  font-family: sans-serif;
  /* min-width: 1189px;*/
  min-width: 1024px;
  margin: 0;
}

@media (max-width: 1024px) {
  body {
    border-top: 5px solid red;
  }
}

#toast-container > div {
  width: 800px;
}

/*
mat-cell .mat-button,
mat-header-cell .mat-raised-button {
  height: 2em;
  line-height: 2em;
  padding: 0 20px;
  margin-right: 0.2em;
}

mat-cell .mat-button .material-icons,
mat-header-cell .mat-raised-button .material-icons {
  font-size: 1.3em;
  margin-top: -0.2em;
  margin-right: 0.2em;
}
*/
.spacer {
  flex: 1 1 auto;
}


mat-header-row.mat-header-row {
  min-height: inherit;
}

mat-header-cell:first-of-type.mat-header-cell,
mat-cell:first-of-type.mat-cell {
  padding-left: 5px;
}

mat-header-cell:last-of-type.mat-header-cell,
mat-cell:last-of-type.mat-cell {
  padding-right: 5px;
}

.mat-chip .mat-icon {
  padding-left: 0.3em;
}

.col-05_3.mat-form-field,
.col-1_3.mat-form-field,
.col-15_3.mat-form-field,
.col-2_3.mat-form-field,
.col-3_3.mat-form-field {
  margin-right: 8px;
}

mat-cell .mat-button {
  padding: 0 10px;
  min-width: 20px;
}

.mat-cell a {
  color: inherit;
  cursor: pointer;
}

.col-05_3 {
  width: calc(16% - 8px);
}

.col-1_3 {
  width: calc(32% - 8px);
}

.col-15_3 {
  width: calc(48% - 8px);
}

.col-2_3 {
  width: calc(64% - 8px);
}

.col-3_3 {
  width: calc(96% - 8px);
}

.cap-overflow {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 0.4em;
}

.mat-row {
  min-height: 32px;
}

mat-row:hover {
  background: whitesmoke;
}

.actionbar.mat-toolbar {
  margin-top: 2em;
  font-size: 1em;

  form {
    width: 100%
  }
}

.mat-button-disabled {
  pointer-events: none;
}

.changes-confirm-overlay-hack {
  z-index: 99999999;
}

.mat-row {
  position: relative;
}

.mat-row-link {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 0;
}

.mat-row a {
  z-index: 1;
}

.header-text h4 {
  font-size: 0.75em;
  font-weight: normal;
}

input[type='number'], .number-input mat-form-field-infix, input.number-input {
  text-align: right;
}

.search-bar {
  margin-left: -22px;
  font-size: 0.95em;
  min-height: 54px;
  height: 54px;

  .mh-search-box {
    margin-top: 4px;

    .mat-form-field {
      width: 10em;
      margin-right: 4px;
    }

    .mat-form-field.long {
      width: 18em;
    }

    .mat-form-field.date {
      width: 8em;
    }
  }
}
